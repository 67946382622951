









































































































































































































































































































































































































































































































































import {Component} from '@/shared/component';
import {AvisLoyer, Token} from '@/models';
import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';
import CrudAvisLoyerEditAvisLoyerDialog from '@/components/Crud/AvisLoyer/EditAvisLoyerDialog.vue';
import CrudAvisLoyerEditCorrespondanceDialog from '@/components/Crud/AvisLoyer/EditCorrespondanceDialog.vue';
import CrudAvisLoyerEditNumberDialog from '@/components/Crud/AvisLoyer/EditNumberDialog.vue';
import CrudAvisLoyerEditLoyerDialog from '@/components/Crud/AvisLoyer/EditLoyerDialog.vue';

import storeAvisLoyer from '@/stores/modules/avisLoyer';
import Checked from "@/components/Checked.vue";
import CrudAvisLoyerEditDialogReglement from "@/components/Crud/AvisLoyer/EditDialogReglements.vue";
import {Stored} from "vue-stored-prop-decorator";
import storeToken from "@/stores/modules/token";

@Component({
	components: {
		CrudAvisLoyerEditAvisLoyerDialog,
		CrudAvisLoyerEditCorrespondanceDialog,
		CrudAvisLoyerEditDialogReglement,
		CrudAvisLoyerEditNumberDialog,
		CrudAvisLoyerEditLoyerDialog,
		Checked,
	}
})
export default class CrudAvisLoyerShow extends AbstractShowVue<AvisLoyer> {

	@Stored(() => storeToken)     private token: Token;

	private editAvisLoyer: AvisLoyer = null;
	private editCorrespondance: AvisLoyer = null;
	private editNumber: AvisLoyer = null;
	private editLoyer: AvisLoyer = null;
	private editReglements: AvisLoyer = null;
	private withSignature: boolean = false;

	private formatter = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

	public constructor() {
		super(storeAvisLoyer);
	}

	private async putChangeState(avisLoyer: AvisLoyer): Promise<void> {
		await storeAvisLoyer.dispatch('putChangeState', avisLoyer);
		await storeAvisLoyer.dispatch('get', this.value.id);
	}

	private get hrefPdf(): string {
		return `${process.env.VUE_APP_BACK_BASE_URL}/pdf/avis-loyer/${this.item.id}?signature=${this.withSignature}&token=${this.token.id}`;
	}

	private get hrefPdfQuittance(): string {
		return `${process.env.VUE_APP_BACK_BASE_URL}/pdf/avis-loyer/quittance/${this.item.id}?signature=${this.withSignature}&token=${this.token.id}`;
	}

	private get hrefPdfAvis(): string {
		return `${process.env.VUE_APP_BACK_BASE_URL}/pdf/avis-loyer/avis/${this.item.id}?signature=${this.withSignature}&token=${this.token.id}`;
	}

}
