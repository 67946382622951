































































































































































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {AvisLoyer} from '@/models';
import storeAvisLoyer from '@/stores/modules/avisLoyer';
import storeLogement from '@/stores/modules/logement';

import InputPhone from '@/components/Input/Phone.vue';
import InputZipCode from "@/components/Input/ZipCode.vue";
import InputDatePicker from "@/components/Input/DatePicker.vue";
import CrudLogementListSelect from "@/components/Crud/Logement/ListSelect.vue";
import {Emit} from "vue-property-decorator";
import store from "@/stores";
import {PeriodicityType} from '@/models';
import storeTva from "@/stores/modules/tva";

@Component({
	components: {
		InputPhone,
		InputZipCode,
		InputDatePicker,
		CrudLogementListSelect,
	}
})
export default class CrudAvisLoyerEditLoyer extends AbstractEditVue<AvisLoyer> {

	private storeLogement = storeLogement;
	private storeTva = storeTva;
	private PeriodicityType = PeriodicityType;
	private montantAdvancesHT = 0;
	private montantRentalHT = 0;
	private montantAnnualHT = 0;

	public constructor() {
		super(
			storeAvisLoyer,
			'Loyer modifié',
			'AvisLoyer ajouté',
		);
	}

	public async mounted(): Promise<void> {
		await super.mounted();
		await storeAvisLoyer.dispatch('getC');
		await storeTva.dispatch('getC');
		this.montantAdvancesHT = parseFloat((this.item.amountAdvances / (1 + (this.item.tva.number / 100))).toFixed(2))
		this.montantRentalHT = parseFloat((this.item.amountRental / (1 + (this.item.tva.number / 100))).toFixed(2))
		this.montantAnnualHT = parseFloat((this.item.amountAnnualAdjustment / (1 + (this.item.tva.number / 100))).toFixed(2))
	}

	protected async onSubmit(): Promise<void> {
		if (this.item.tvaType === 'HT') {
			this.updateTTC('all')
		}
		await super.onSubmit();
	}

	private updateTTC(label) {
		if (label === 'advances') {
			this.item.amountAdvances = parseFloat((this.montantAdvancesHT * (this.item.tva.number / 100) + this.montantAdvancesHT).toFixed(2));
		} else if (label === 'rental') {
			this.item.amountRental = parseFloat((this.montantRentalHT * (this.item.tva.number / 100) + this.montantRentalHT).toFixed(2));
		} else if (label === 'annual') {
			this.item.amountAnnualAdjustment = parseFloat((this.montantAnnualHT * (this.item.tva.number / 100) + this.montantAnnualHT).toFixed(2));
		} else if (label === 'all') {
			this.item.amountAdvances = parseFloat((this.montantAdvancesHT * (this.item.tva.number / 100) + this.montantAdvancesHT).toFixed(2));
			this.item.amountRental = parseFloat((this.montantRentalHT * (this.item.tva.number / 100) + this.montantRentalHT).toFixed(2));
			this.item.amountAnnualAdjustment = parseFloat((this.montantAnnualHT * (this.item.tva.number / 100) + this.montantAnnualHT).toFixed(2));
		} else if (label === 'allRevert') {
			this.montantAdvancesHT = parseFloat((this.item.amountAdvances / (1 + (this.item.tva.number / 100))).toFixed(2))
			this.montantRentalHT = parseFloat((this.item.amountRental / (1 + (this.item.tva.number / 100))).toFixed(2))
			this.montantAnnualHT = parseFloat((this.item.amountAnnualAdjustment / (1 + (this.item.tva.number / 100))).toFixed(2))
		}
	}

	private labelAdjustment() {
		if (this.item.tvaType === 'HT') {
			return 'Montant des régularisations annuelle TTC'
		} else {
			return 'Montant des régularisations annuelle'
		}
	}

	private labelAdvances() {
		if (this.item.tvaType === 'HT') {
			return 'Montant des avances/charges TTC'
		} else {
			return 'Montant des avances/charges'
		}
	}

	private labelRental() {
		if (this.item.tvaType === 'HT') {
			return 'Montant du loyer TTC'
		} else {
			return 'Montant du loyer'
		}
	}

}
